import { Subject } from "rxjs"
import apiResponse from "../../../services/apiResponse"
import IndexRepository from "../repositories/index-repository"

class IndexBloc {
    productDataChannel = new Subject()
    checkDiscountCodeChannel = new Subject()
    paymentMethodChannel = new Subject()
    orderChannel = new Subject()
    repository = new IndexRepository()

    constructor() {
        this.productDataChannel.next({ status: apiResponse.INITIAL })
        this.checkDiscountCodeChannel.next({ status: apiResponse.INITIAL })
        this.paymentMethodChannel.next({ status: apiResponse.INITIAL })
        this.orderChannel.next({ status: apiResponse.INITIAL })
    }

    fetchProductData = async () => {
        this.productDataChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository
                .fetchProductData()
                .then((result) => {
                    if (result.status) {
                        this.productDataChannel.next({
                            status: apiResponse.COMPLETED,
                            data: result,
                        })
                    } else {
                        this.productDataChannel.next({
                            status: apiResponse.ERROR,
                            data: result.message,
                        })
                    }
                })
                .catch((error) => {
                    this.productDataChannel.next({
                        status: apiResponse.ERROR,
                        data: error,
                    })
                })
        } catch (error) {
            console.log("rxjs", "Channel productDataChannel Unsubscribed")
        }
    }

    fetchCheckDiscountCode = async (query) => {
        this.checkDiscountCodeChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository
                .fetchCheckDiscountCode(query)
                .then((result) => {
                    if (result.status) {
                        this.checkDiscountCodeChannel.next({
                            status: apiResponse.COMPLETED,
                            data: result,
                        })
                    } else {
                        this.checkDiscountCodeChannel.next({
                            status: apiResponse.ERROR,
                            data: result.message,
                        })
                    }
                })
                .catch((error) => {
                    this.checkDiscountCodeChannel.next({
                        status: apiResponse.ERROR,
                        data: error,
                    })
                })
        } catch (error) {
            console.log("rxjs", "Channel checkDiscountCodeChannel Unsubscribed")
        }
    }

    fetchPaymentMethod = async () => {
        this.paymentMethodChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository
                .fetchPaymentMethod()
                .then((result) => {
                    if (result.status) {
                        this.paymentMethodChannel.next({
                            status: apiResponse.COMPLETED,
                            data: result,
                        })
                    } else {
                        this.paymentMethodChannel.next({
                            status: apiResponse.ERROR,
                            data: result.message,
                        })
                    }
                })
                .catch((error) => {
                    this.paymentMethodChannel.next({
                        status: apiResponse.ERROR,
                        data: error,
                    })
                })
        } catch (error) {
            console.log("rxjs", "Channel paymentMethodChannel Unsubscribed")
        }
    }
    fetchOrder = async (query) => {
        this.orderChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository
                .fetchOrder(query)
                .then((result) => {
                    if (result.status) {
                        this.orderChannel.next({
                            status: apiResponse.COMPLETED,
                            data: result,
                        })
                    } else {
                        this.orderChannel.next({
                            status: apiResponse.ERROR,
                            data: result.message,
                        })
                    }
                })
                .catch((error) => {
                    this.orderChannel.next({
                        status: apiResponse.ERROR,
                        data: error,
                    })
                })
        } catch (error) {
            console.log("rxjs", "Channel orderChannel Unsubscribed")
        }
    }
}

export default IndexBloc
