import {
    apiRequest,
    getRegistrationContent,
} from "../../../services/adapters/base"

class IndexRepository {
    fetchContent = async () => {
        return await apiRequest("get", getRegistrationContent, {}, true)
    }
}

export default IndexRepository
