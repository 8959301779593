import LogoWhite from "../../../assets/images/logo-white.png"
const LayoutFooterComponent = () => {
    return (
        <div className="fixed bottom-0 z-40 flex h-12 w-full">
            <div className="fixed left-0 right-32 mt-4 flex-grow bg-iief-green px-4 py-1 text-xs text-white">
                <p className="text-md muted truncate text-white">
                    Copyright © 2023 - {localStorage.getItem("copyright")}
                </p>
            </div>
            <div className="fixed right-0 -mr-9 h-10 w-48 flex-none skew-x-[-22deg] transform border-2 border-iief-orange"></div>
            <div className="fixed right-0 -mr-6 mt-2 h-10 w-48 flex-none skew-x-[-22deg] transform bg-iief-orange text-xs">
                <div className="text-md flex skew-x-[22deg] transform items-center justify-center gap-2 pe-12 ps-6">
                    <img
                        alt=""
                        src={LogoWhite}
                        className="h-[34px] w-[130px] pt-[6px] drop-shadow"
                    />
                </div>
            </div>
        </div>
    )
}

export default LayoutFooterComponent
