import axios from "axios"

export const apiCancel = () => {
    return axios.CancelToken.source()
}
export const apiWrapper = async (
    baseUrl,
    initial,
    appCode,
    outputType,
    method,
    route,
    data = { params: null, body: null, cancelToken: null },
) => {
    const onSuccess = (response) => {
        if (response.data.tokenExpired) {
            localStorage.setItem(
                initial + "AccessExpired",
                response.data.tokenExpired,
            )
        }
        localStorage.setItem(
            "localTime",
            Math.floor(new Date().getTime() / 1000),
        )
        localStorage.setItem("serverTime", response.data.serverTime)
        return response.data
    }

    const onError = (error) => {
        return Promise.reject(error.response || error.message)
    }

    return axios
        .create({
            baseURL: baseUrl,
            headers: {
                initial: initial,
                appCode: appCode,
                accessToken: localStorage.getItem(initial + "AccessToken"),
                tokenId: localStorage.getItem("tokenId"),
            },
        })({
            method,
            url: route + outputType,
            params: data.params,
            data: data.body,
            cancelToken: data.cancelToken,
        })
        .then(onSuccess)
        .catch(onError)
}
