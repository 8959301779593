"use client"
import React, { useState, useEffect } from "react"
import { useIdleTimer } from "react-idle-timer"
import YouTube from "react-youtube"
import $ from "jquery"

const GlobalIdleComponent = () => {
    const [isIdle, setIsIdle] = useState(false)
    const [player, setPlayer] = useState(null)

    const videoId = "TFw014bLOqk"

    const handleOnIdle = () => {
        setIsIdle(true)
    }

    const { getRemainingTime } = useIdleTimer({
        timeout: 480000,
        onIdle: handleOnIdle,
    })

    useEffect(() => {
        setInterval(() => {
            if (getRemainingTime() > 0) {
                setIsIdle(false)
            }
        }, 1)
    }, [])

    useEffect(() => {
        if (isIdle) {
            $("#player-idle").fadeIn()
            if (player) {
                player.playVideo()
            }
        } else {
            $("#player-idle").fadeOut()
            if (player) {
                player.stopVideo()
            }
        }
    }, [isIdle])

    const onReady = (event) => {
        setPlayer(event.target)
    }

    const onEnd = () => {
        player.playVideo()
    }

    return (
        <div
            className="fixed z-50 hidden h-full w-full flex-1 bg-black"
            id="player-idle"
        >
            <YouTube
                className="fixed h-full w-full"
                videoId={videoId}
                onReady={onReady}
                onEnd={onEnd}
                opts={{ width: "100%", height: "100%", loop: 1 }}
            />
            <div className="bg-black/1 fixed h-full w-full flex-1"></div>
        </div>
    )
}

export default GlobalIdleComponent
