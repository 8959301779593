import {
    apiRequest,
    getInformationList,
    getInformationContent,
} from "../../../services/adapters/base"

class IndexRepository {
    fetchList = async () => {
        return await apiRequest("get", getInformationList, {}, true)
    }
    fetchContent = async () => {
        return await apiRequest("get", getInformationContent, {}, true)
    }
}

export default IndexRepository
