import {
    apiRequest,
    getOrderProducts,
    checkOrderDiscount,
    getPaymentMethod,
    submitOrder,
} from "../../../services/adapters/base"

class IndexRepository {
    fetchProductData = async () => {
        return await apiRequest("get", getOrderProducts, {}, true)
    }

    fetchCheckDiscountCode = async (query) => {
        return await apiRequest(
            "get",
            checkOrderDiscount,
            { params: query },
            true,
        )
    }

    fetchPaymentMethod = async () => {
        return await apiRequest("get", getPaymentMethod, {}, true)
    }

    fetchOrder = async (query) => {
        return await apiRequest("post", submitOrder, { body: query }, true)
    }
}

export default IndexRepository
