import { Component } from "react"
import Shimmer from "react-shimmer-effect"
import IndexBloc from "../blocs/index-bloc"
import apiResponse from "../../../services/apiResponse"

class InformationView extends Component {
    indexBloc = new IndexBloc()

    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            informationData: {},
        }
    }

    componentDidMount() {
        this.indexBloc.contentChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loading: false,
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loading: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.results
                    this.setState({
                        informationData: response,
                        loading: false,
                    })
                    break
                case apiResponse.ERROR:
                    break
                default:
                    break
            }
        })
        setTimeout(() => {
            this.indexBloc.fetchContent()
        }, 500)
    }

    componentWillUnmount() {
        this.indexBloc.contentChannel.unsubscribe()
    }

    render() {
        return (
            <div className="relative mx-auto min-h-[200px] w-full rounded-2xl bg-white px-6 py-5 shadow-2xl">
                <div className="flex">
                    <div className="relative bottom-0 flex h-12 w-full">
                        <div className="absolute ms-3 h-10 w-[220px] flex-1 skew-x-[-22deg] transform border-2 border-iief-orange"></div>
                        <div className="absolute mt-2 h-10 w-[220px] flex-1 skew-x-[-22deg] transform bg-iief-green text-xs drop-shadow">
                            <div className="text-md flex skew-x-[22deg] transform items-center justify-center gap-2 pe-12">
                                <div className="mb-6 px-6 pt-[7px] text-xl font-bold text-white">
                                    Information
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="mt-2 bg-slate-100 p-4 font-semibold">
                    <div className="text-xs text-ets-green">
                        The TOEFL iBT®️ test accurately measures how well you
                        can read, listen, speak and write in English in a
                        college or university classroom. It helps you stand out
                        confidently and it comes with a clear advantage —
                        college and universities know you are ready to succeed
                        and communicate in English.
                    </div>
                    <div className="mt-2 text-xs text-ets-green">
                        TOEFL iBT®️ scores are accepted worldwide by more than
                        12,000 universities and other institutions in over 160
                        countries. More institutions accept TOEFL scores than
                        scores from any other English-language test, and over 35
                        million people have taken the test since it began in
                        1964.
                    </div>
                </div> */}
                {this.state.loading ? (
                    <>
                        {Array.from({ length: 3 }, (data, index) => {
                            return (
                                <div className="mx-auto flex flex-col pt-10">
                                    <div className="mb-1 text-sm font-bold text-iief-green">
                                        <Shimmer>
                                            <div
                                                className="rounded"
                                                style={{
                                                    width: 90,
                                                    height: 14,
                                                    paddingBottom: 0,
                                                    marginBottom: 0,
                                                }}
                                            ></div>
                                        </Shimmer>
                                    </div>
                                    <div className="mb-1 ms-4 text-xs text-gray-500">
                                        <Shimmer>
                                            <div
                                                className="rounded"
                                                style={{
                                                    width: "80%",
                                                    height: 12,
                                                    paddingBottom: 0,
                                                    marginBottom: 0,
                                                }}
                                            ></div>
                                        </Shimmer>
                                    </div>
                                    <div className="mb-1 ms-4 text-xs text-gray-500">
                                        <Shimmer>
                                            <div
                                                className="rounded"
                                                style={{
                                                    width: "80%",
                                                    height: 12,
                                                    paddingBottom: 0,
                                                    marginBottom: 0,
                                                }}
                                            ></div>
                                        </Shimmer>
                                    </div>
                                    <div className="mb-1 ms-4 text-xs text-gray-500">
                                        <Shimmer>
                                            <div
                                                className="rounded"
                                                style={{
                                                    width: "40%",
                                                    height: 12,
                                                    paddingBottom: 0,
                                                    marginBottom: 0,
                                                }}
                                            ></div>
                                        </Shimmer>
                                    </div>
                                </div>
                            )
                        })}
                    </>
                ) : (
                    <>
                        {(this.state.informationData.content_title ?? null) !==
                            null && (
                            <div className="ck-content mb-5 mt-2 bg-slate-100 p-4 font-semibold">
                                <div
                                    className="text-xs text-ets-green"
                                    dangerouslySetInnerHTML={{
                                        __html: this.state.informationData
                                            .content_title,
                                    }}
                                />
                            </div>
                        )}

                        {(this.state.informationData.content_data ?? null) !==
                            null && (
                            <div
                                className="ck-content max-w-none pt-5 text-[12px] text-gray-500"
                                dangerouslySetInnerHTML={{
                                    __html: this.state.informationData
                                        .content_data,
                                }}
                            ></div>
                        )}

                        {/* {this.state.informationData.map((data, i) => {
                            return (
                                <div
                                    className="mx-auto flex flex-col pt-10"
                                    key={"information_" + i}
                                >
                                    <div className="mb-1 text-sm font-bold text-iief-green">
                                        {data.information_name}
                                    </div>
                                    {data.items.map((item, x) => {
                                        return (
                                            <div
                                                className="mb-1 ms-4 flex text-xs text-gray-500"
                                                key={"informationItem_" + x}
                                            >
                                                {item.information_item_prefix !==
                                                    null && (
                                                    <>
                                                        <div className="me-2 min-w-[80px] font-semibold">
                                                            {
                                                                item.information_item_prefix
                                                            }
                                                        </div>
                                                        <div className="me-2 font-semibold">
                                                            :
                                                        </div>
                                                    </>
                                                )}
                                                {item.information_item_content !==
                                                    null && (
                                                    <div className="me-2">
                                                        {
                                                            item.information_item_content
                                                        }
                                                    </div>
                                                )}
                                                {item.information_item_subfix !==
                                                    null && (
                                                    <div className="rounded-full bg-iief-orange px-2 pb-[2px] text-[10px] text-white">
                                                        <small>
                                                            {
                                                                item.information_item_subfix
                                                            }
                                                        </small>
                                                    </div>
                                                )}
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        })} */}
                    </>
                )}
                <div className="pt-10"></div>
            </div>
        )
    }
}

export default InformationView
