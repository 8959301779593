import { Component } from "react"
import Shimmer from "react-shimmer-effect"

import IndexBloc from "../blocs/index-bloc"
import apiResponse from "../../../services/apiResponse"

import { FaLocationDot } from "react-icons/fa6"

class TestCenterView extends Component {
    indexBloc = new IndexBloc()

    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            testCenterData: [],
        }
    }

    componentDidMount() {
        this.indexBloc.listChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loading: false,
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loading: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.results
                    this.setState({
                        testCenterData: response,
                        loading: false,
                    })
                    break
                case apiResponse.ERROR:
                    break
                default:
                    break
            }
        })
        setTimeout(() => {
            this.indexBloc.fetchList()
        }, 500)
    }

    componentWillUnmount() {
        this.indexBloc.listChannel.unsubscribe()
    }

    render() {
        return (
            <div className="relative mx-auto min-h-[200px] w-full rounded-2xl bg-white px-6 py-5 shadow-2xl">
                <div className="flex">
                    <div className="relative bottom-0 flex h-12 w-full">
                        <div className="absolute ms-3 h-10 w-[220px] flex-1 skew-x-[-22deg] transform border-2 border-iief-orange"></div>
                        <div className="absolute mt-2 h-10 w-[220px] flex-1 skew-x-[-22deg] transform bg-iief-green text-xs drop-shadow">
                            <div className="text-md flex skew-x-[22deg] transform items-center justify-center gap-2 pe-12">
                                <div className="mb-6 px-6 pt-[7px] text-xl font-bold text-white">
                                    Test Center
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-2 bg-slate-100 p-4 font-semibold">
                    <div className="text-xs text-ets-green">
                        When selecting a test date, consider your deadlines :
                        Pick a test date at least 2 to 3 months before your
                        earliest admissions deadline to ensure your scores will
                        be reported in time.
                    </div>
                    <div className="mt-2 text-xs text-ets-green">
                        Score reports are sent to your designated score
                        recipients approximately 4–16 business days after your
                        test date, depending on their chosen delivery method
                    </div>
                </div>
                {this.state.loading ? (
                    <>
                        {Array.from({ length: 5 }, (data, index) => {
                            return (
                                <div
                                    className="flex justify-start pt-10"
                                    key={"testCenter_" + index}
                                >
                                    <div className="me-3 pt-2">
                                        <FaLocationDot className="text-2xl text-zinc-400 drop-shadow-xl" />
                                    </div>
                                    <div className="items-justify-start flex flex-col">
                                        <div className="mb-1 text-sm font-bold text-iief-green">
                                            <Shimmer>
                                                <div
                                                    className="rounded"
                                                    style={{
                                                        width: 90,
                                                        height: 14,
                                                        paddingBottom: 0,
                                                        marginBottom: 0,
                                                    }}
                                                ></div>
                                            </Shimmer>
                                        </div>
                                        <div className="w-full text-xs text-gray-500">
                                            <Shimmer>
                                                <div
                                                    className="rounded"
                                                    style={{
                                                        width: 300,
                                                        height: 12,
                                                        paddingBottom: 0,
                                                        marginBottom: 0,
                                                    }}
                                                ></div>
                                            </Shimmer>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </>
                ) : (
                    <>
                        {this.state.testCenterData.map((data, i) => {
                            return (
                                <div className="flex justify-start pt-10">
                                    <div className="me-3 pt-2">
                                        <FaLocationDot className="text-2xl text-iief-orange drop-shadow-xl" />
                                    </div>
                                    <div className="items-justify-start flex flex-col">
                                        <div className="mb-1 text-sm font-bold text-iief-green">
                                            {data.test_center_name}
                                        </div>
                                        <div className="text-xs text-gray-500">
                                            {data.test_center_address === null
                                                ? "1"
                                                : data.test_center_address}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </>
                )}
                <div className="pt-10"></div>
            </div>
        )
    }
}

export default TestCenterView
