import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import LayoutMainComponent from "./helpers/components/layout/MainComponent"
import GlobalPreloadComponent from "./helpers/components/PreloadComponent"
import GlobalErrorNetworkComponent from "./helpers/components/ErrorNetworkComponent"

import {
    apiRequest,
    initial,
    connect,
    getConfig,
    getAccessToken,
} from "./services/adapters/base"

ReactDOM.render(
    <GlobalPreloadComponent text="Initializing Application..." />,
    document.getElementById("root"),
)
setTimeout(() => {
    testConnection()
}, 1000)

async function testConnection() {
    ReactDOM.render(
        <GlobalPreloadComponent text="Checking Connection..." />,
        document.getElementById("root"),
    )

    await apiRequest("get", connect, {}, false)
        .then(async (result) => {
            if (result.serverTime) {
                localStorage.setItem(
                    "localTime",
                    Math.floor(new Date().getTime() / 1000),
                )
                localStorage.setItem("serverTime", result.serverTime)
            }
            setTimeout(() => {
                requestToken()
            }, 500)
        })
        .catch((result) => {
            ReactDOM.render(
                <GlobalErrorNetworkComponent />,
                document.getElementById("root"),
            )
        })
}

async function requestToken() {
    ReactDOM.render(
        <GlobalPreloadComponent text="Requesting Access Token..." />,
        document.getElementById("root"),
    )
    await apiRequest("post", getAccessToken, {}, false)
        .then(async (result) => {
            if (result.serverTime) {
                localStorage.setItem(
                    "localTime",
                    Math.floor(new Date().getTime() / 1000),
                )
                localStorage.setItem("serverTime", result.serverTime)
                localStorage.setItem(
                    initial + "AccessToken",
                    result.results.access_token,
                )
            }
            setTimeout(() => {
                getCopyright()
            }, 500)
        })
        .catch((result) => {
            ReactDOM.render(
                <GlobalErrorNetworkComponent />,
                document.getElementById("root"),
            )
        })
}

async function getCopyright() {
    ReactDOM.render(
        <GlobalPreloadComponent text="Autoload Configuration data..." />,
        document.getElementById("root"),
    )
    await apiRequest(
        "get",
        getConfig,
        { params: { code: "layout_copyright" } },
        false,
    )
        .then(async (result) => {
            if (result.serverTime) {
                localStorage.setItem(
                    "localTime",
                    Math.floor(new Date().getTime() / 1000),
                )
                localStorage.setItem("serverTime", result.serverTime)
                localStorage.setItem("copyright", result.results)
            }
            setTimeout(() => {
                gotoRoot()
            }, 500)
        })
        .catch((result) => {
            ReactDOM.render(
                <GlobalErrorNetworkComponent />,
                document.getElementById("root"),
            )
        })
}

/* async function requestConfig() {
    ReactDOM.render(
        <GlobalPreloadComponent text="Requesting Access Token..." />,
        document.getElementById("root"),
    )
    await apiRequest("post", getAccessToken, {}, false)
        .then(async (result) => {
            if (result.serverTime) {
                localStorage.setItem(
                    "localTime",
                    Math.floor(new Date().getTime() / 1000),
                )
                localStorage.setItem("serverTime", result.serverTime)
                localStorage.setItem(
                    initial + "AccessToken",
                    result.results.access_token,
                )
            }
            //getUserdata()
        })
        .catch((result) => {
            ReactDOM.render(
                <GlobalErrorNetworkComponent />,
                document.getElementById("root"),
            )
        })
} */

function gotoRoot() {
    ReactDOM.render(
        <React.StrictMode>
            <LayoutMainComponent />
        </React.StrictMode>,
        document.getElementById("root"),
    )
}
