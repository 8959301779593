import EtsLogo from "../../../assets/svg/logo.svg"

const LayoutBackgroundComponent = () => {
    return (
        <>
            <div
                className="fixed h-full w-full bg-cover bg-fixed bg-center bg-no-repeat"
                style={{ backgroundImage: `url('${EtsLogo}')` }}
            ></div>
        </>
    )
}

export default LayoutBackgroundComponent
