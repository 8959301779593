"use client"
import React, { useState, useEffect } from "react"
import Logo from "../../assets/images/logo.png"
import Lottie from "lottie-react"
import lottieLoading from "../../assets/lotties/loading.json"

const GlobalErrorNetworkComponent = () => {
    return (
        <div className="fixed z-50 grid h-full w-full flex-1 place-items-center bg-white">
            <div>
                <img className="h-[100px] drop-shadow" src={Logo} />
                <Lottie
                    animationData={lottieLoading}
                    loop={true}
                    className="h-[100px]"
                />
                <div className="text-center text-red-500">
                    Connection to Server Failed
                </div>
            </div>
        </div>
    )
}

export default GlobalErrorNetworkComponent
