import Pattern14 from "../../../assets/svg/pattern.14.svg"
import Pattern4 from "../../../assets/svg/pattern.4.svg"
import Pattern7 from "../../../assets/svg/pattern.7.svg"
import Pattern12 from "../../../assets/svg/pattern.12.svg"
import Pattern6 from "../../../assets/svg/pattern.6.svg"
import Pattern8 from "../../../assets/svg/pattern.8.svg"
import Pattern1 from "../../../assets/svg/pattern.1.svg"

const PatternMenuComponent = () => {
    return (
        <>
            <div className="fixed bottom-[52px] left-[32px]">
                <img alt="" src={Pattern14} />
            </div>
            <div className="fixed bottom-[72px] left-[165px]">
                <img alt="" src={Pattern4} />
            </div>
            <div className="fixed bottom-[72px] left-[160px]">
                <img alt="" src={Pattern7} />
            </div>
            <div className="fixed bottom-[72px] left-[66px]">
                <img alt="" src={Pattern12} />
            </div>
            <div className="fixed bottom-[272px] left-[96px]">
                <img alt="" src={Pattern6} />
            </div>
            <div className="fixed bottom-[172px] left-[266px]">
                <img alt="" src={Pattern8} />
            </div>
            <div className="fixed bottom-[2px] left-[6px]">
                <img alt="" src={Pattern1} />
            </div>
        </>
    )
}

export default PatternMenuComponent
