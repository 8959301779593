const CheckOrderView = () => {
    return (
        <div className="relative mx-auto min-h-[200px] w-full rounded-2xl bg-white px-6 py-5 shadow-2xl">
            <div className="flex">
                <div className="relative bottom-0 flex h-12 w-full">
                    <div className="absolute ms-3 h-10 w-[230px] flex-1 skew-x-[-22deg] transform border-2 border-iief-orange"></div>
                    <div className="absolute mt-2 h-10 w-[230px] flex-1 skew-x-[-22deg] transform bg-iief-green text-xs drop-shadow">
                        <div className="text-md flex skew-x-[22deg] transform items-center justify-center gap-2 pe-12">
                            <div className="mb-6 px-6 pt-[7px] text-xl font-bold text-white">
                                Check Order
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CheckOrderView
