import { useState } from "react"
import OrderVoucherChooseView from "../components/choose"
import OrderVoucherCheckoutView from "../components/checkout"
import OrderVoucherPaymentView from "../components/payment"

const OrderVoucherView = () => {
    const [page, setPage] = useState("choose")
    const [cart, setCart] = useState({
        items: [],
        discount: {
            discount_uuid: null,
            discount_code: null,
            discount_type: null,
            discount_price: 0,
            discount_total: 0,
        },
    })
    const [payment, setPayment] = useState({})

    const gotoCheckout = (cart) => {
        setPage("loading")
        setTimeout(() => {
            setCart(cart)
            setPage("checkout")
        }, 10)
    }

    const gotoChoose = (cart) => {
        setPage("loading")
        setTimeout(() => {
            setCart(cart)
            setPage("choose")
        }, 1)
    }

    const gotoPayment = (payment) => {
        setPage("loading")
        setTimeout(() => {
            setPayment(payment)
            setPage("payment")
        }, 1)
    }

    return (
        <div className="relative mx-auto min-h-[200px] w-full rounded-2xl bg-white px-6 py-5 shadow-2xl">
            <div className="flex">
                <div className="relative bottom-0 flex h-12 w-full">
                    <div className="absolute ms-3 h-10 w-[250px] flex-1 skew-x-[-22deg] transform border-2 border-iief-orange"></div>
                    <div className="absolute mt-2 h-10 w-[250px] flex-1 skew-x-[-22deg] transform bg-iief-green text-xs drop-shadow">
                        <div className="text-md flex skew-x-[22deg] transform items-center justify-center gap-2 pe-12">
                            <div className="mb-6 px-6 pt-[7px] text-xl font-bold text-white">
                                Order Voucher
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-2 bg-slate-100 p-4 font-semibold">
                <div className="text-xs text-ets-green">
                    Voucher covers the TOEFL iBT test fee. Test takers can apply
                    vouchers at TOEFL iBT registration page :
                    www.ets.org/mytoefl at the checkout page
                </div>
            </div>
            <div className="mt-10" />
            <div>
                {page === "choose" ? (
                    <OrderVoucherChooseView
                        carts={cart}
                        checkout={gotoCheckout}
                    />
                ) : (
                    <>
                        {page === "checkout" ? (
                            <OrderVoucherCheckoutView
                                carts={cart}
                                choose={gotoChoose}
                                payment={gotoPayment}
                            />
                        ) : (
                            <>
                                {page === "payment" && (
                                    <OrderVoucherPaymentView data={payment} />
                                )}
                            </>
                        )}
                    </>
                )}
            </div>
            <div className="mt-10" />
        </div>
    )
}

export default OrderVoucherView
