import { Component } from "react"
import Shimmer from "react-shimmer-effect"
import Carousel from "nuka-carousel"
import IndexBloc from "../blocs/index-bloc"
import apiResponse from "../../../services/apiResponse"

class HomeView extends Component {
    indexBloc = new IndexBloc()

    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            sliderData: [],
        }
    }

    componentDidMount() {
        this.indexBloc.listChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loading: false,
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loading: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.results
                    this.setState(
                        {
                            sliderData: response,
                            loading: false,
                        },
                        () => {
                            console.log(this.state.sliderData)
                        },
                    )
                    break
                case apiResponse.ERROR:
                    break
                default:
                    break
            }
        })
        setTimeout(() => {
            this.indexBloc.fetchList()
        }, 500)
    }

    componentWillUnmount() {
        this.indexBloc.listChannel.unsubscribe()
    }

    render() {
        return (
            <div className="w-full">
                {this.state.loading ? (
                    <></>
                ) : (
                    <Carousel
                        autoplay={true}
                        autoplayInterval={5000}
                        withoutControls={true}
                        pauseOnHover={false}
                        wrapAround={true}
                        zoomScale={0.5}
                    >
                        {this.state.sliderData.map((data, i) => {
                            return (
                                <img
                                    src={data.slider_image}
                                    alt={data.slider_uuid}
                                />
                            )
                        })}
                    </Carousel>
                )}
            </div>
        )
    }
}

export default HomeView
