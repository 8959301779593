import { Component } from "react"
import { QRCode } from "react-qrcode-logo"
import LogoIief from "../../../assets/images/iief.png"
import LogoEts from "../../../assets/images/logo.png"
import QrisName from "../../../assets/images/banks/qris-name.png"

class OrderVoucherPaymentView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            paymentSuccess: false,
        }
    }

    componentDidMount() {}

    render() {
        return (
            <>
                {!this.state.paymentSuccess ? (
                    <>
                        {this.props.data.payment === "qris" ? (
                            <div className="flex grow">
                                <div className="grow" />
                                <div className="mb-2 mt-2 w-[284px] grow-0 rounded border bg-white p-4 drop-shadow-lg">
                                    <div className="mb-2 flex justify-between">
                                        <img
                                            className="h-[18px]"
                                            src={QrisName}
                                        />
                                        <img
                                            className="h-[18px]"
                                            src={LogoEts}
                                        />
                                    </div>
                                    <div className="text-md mb-2 text-center font-bold text-gray-500">
                                        {this.props.data.code}
                                    </div>
                                    <QRCode
                                        value={this.props.data.number}
                                        logoImage={LogoIief}
                                        logoPadding={4}
                                        logoWidth={30}
                                        logoHeight={30}
                                        quietZone={0}
                                        removeQrCodeBehindLogo
                                        size={250}
                                        fgColor="#445d6e"
                                        eyeColor={{
                                            outer: "#087f83",
                                            inner: "#ec7224",
                                        }}
                                    />
                                    <div className="mt-2 text-center text-sm font-bold text-ets-green">
                                        Waiting payment...
                                    </div>
                                    <div className="mb-2 text-center text-lg font-bold text-iief-orange">
                                        Rp
                                        {this.props.data.amount
                                            .toString()
                                            .replace(".", ",")
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ".",
                                            )}
                                    </div>
                                    <div className="rounded bg-iief-green py-2 text-center text-[10px] font-bold text-white">
                                        <div>
                                            This QR code is only for one-time
                                            transaction
                                        </div>
                                        <div className="font-normal">
                                            Valid until {this.props.data.date}{" "}
                                            WIB
                                        </div>
                                    </div>
                                </div>
                                <div className="grow" />
                            </div>
                        ) : (
                            <>
                                <div className="my-4 mb-8">
                                    <div className="border-b-2 pb-2 font-bold text-slate-500">
                                        <img
                                            className="h-[44px]"
                                            src={this.props.data.logo}
                                        />
                                    </div>
                                </div>
                                <div className="mb-10">
                                    <div className="ms-8 pb-2 text-sm text-gray-600 sm:flex">
                                        <div className="w-52">Order Code</div>
                                        <div className="font-bold text-iief-green">
                                            {this.props.data.code}
                                        </div>
                                    </div>
                                    <div className="ms-8 pb-2 text-sm text-gray-600 sm:flex">
                                        <div className="w-52">
                                            Virtaul Account Number
                                        </div>
                                        <div className="text-iief-green">
                                            {this.props.data.number}
                                        </div>
                                    </div>
                                    <div className="ms-8 pb-2 text-sm text-gray-600 sm:flex">
                                        <div className="w-52">Nominal</div>
                                        <div className="text-iief-green">
                                            Rp
                                            {this.props.data.amount
                                                .toString()
                                                .replace(".", ",")
                                                .toString()
                                                .replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ".",
                                                )}
                                        </div>
                                    </div>
                                    <div className="ms-8 pb-2 text-sm text-gray-600 sm:flex">
                                        <div className="w-52">Status</div>
                                        <div className="text-iief-green">
                                            Waiting payment...
                                        </div>
                                    </div>
                                </div>
                                {this.props.data.step.length > 0 && (
                                    <div className="my-4 mb-8">
                                        <div className="border-b-2 pb-2 font-bold text-slate-500">
                                            <div className="grow-none text-md">
                                                How to Pay?
                                            </div>
                                        </div>
                                        {this.props.data.step.map((data, _) => {
                                            return (
                                                <>
                                                    <div className="mt-3 bg-slate-100 px-4 py-2 font-semibold">
                                                        <div className="text-sm text-iief-green">
                                                            {
                                                                data.payment_step_name
                                                            }
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="prose px-4 pb-4 text-xs"
                                                        dangerouslySetInnerHTML={{
                                                            __html: data.payment_step_data,
                                                        }}
                                                    />
                                                </>
                                            )
                                        })}
                                    </div>
                                )}
                            </>
                        )}
                    </>
                ) : (
                    <></>
                )}
            </>
        )
    }
}

export default OrderVoucherPaymentView
