import { Link } from "react-router-dom"
import Logo from "../../../assets/images/logo.png"
const LayoutHeaderComponent = () => {
    return (
        <div className="fixed z-40 flex h-20 w-full flex-none justify-between border-b-4 border-iief-grey bg-white drop-shadow">
            <div className="fixed flex w-full justify-between py-3 ps-10">
                <Link to="/">
                    <img
                        className="h-[50px] w-[219px] drop-shadow"
                        src={Logo}
                    />
                </Link>
                <Link to="/check">
                    <div className="my-[10px] me-4 h-[34px] scale-[90%] cursor-pointer overflow-hidden rounded-full border-2 border-white bg-iief-orange px-6 pt-[2.2px] shadow-md ring-2 ring-iief-orange transition duration-200 ease-in-out hover:scale-100">
                        <p className="text-md truncate text-ellipsis text-white">
                            Check Order
                        </p>
                    </div>
                </Link>
            </div>
            <div className="mt-10 flex h-9 w-full justify-end bg-ets-grey"></div>
            {/* <div className="bg-iief-orange ring-iief-orange my-5 me-10 h-[34px] scale-[90%] cursor-pointer overflow-hidden rounded-full border-2 border-white px-6 pt-[2.2px] shadow-md ring-2 transition duration-200 ease-in-out hover:scale-100">
        <p className="text-md truncate text-white">Check Payment Status</p>
      </div> */}
        </div>
    )
}

export default LayoutHeaderComponent
