import { useState, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import MenuPrefix from "../../../assets/svg/menu-prefix.svg"
import MenuSubfix from "../../../assets/svg/menu-subfix.svg"
import $ from "jquery"

const ContainerMenuComponent = () => {
    const history = useHistory()
    const location = useLocation()

    const [selected, setSelected] = useState(-1)
    const menus = [
        {
            title: "Information",
            link: "/information",
            description: "Learn more about TOEFL iBT®️ test",
        },
        {
            title: "Test Center",
            link: "/testcenter",
            description: "Find a TOEFL iBT®️  test center",
        },
        {
            title: "Registration",
            link: "/registration",
            description: "Quicker & easier process",
        },
        {
            title: "Order Voucher",
            link: "/order",
            description: "Voucher covers the TOEFL iBT®️ test",
        },
        {
            title: "Contact Us",
            link: "/contact",
            description: "Have further inquiries?",
        },
    ]

    useEffect(() => {
        setSelected(-1)
        for (let i = 0; i < menus.length; ++i) {
            if (menus[i].link === location.pathname) {
                $("#root").scrollTop()
                setSelected(i)
            }
        }
    }, [location])

    const menuSelecting = (i, link) => {
        //setSelected(i)
        $("#root").scrollTop()
        history.push(link)
    }

    return (
        <div className="no-scrollbar portrait::overflow-y-hidden fixed top-20 z-30 w-full drop-shadow portrait:top-16 portrait:overflow-x-scroll portrait:bg-[#ffffff]/50 portrait:backdrop-blur landscape:h-full landscape:w-[30%] landscape:overflow-x-hidden landscape:overflow-y-scroll landscape:pb-32">
            <div className="flex flex-col pt-10 portrait:w-[1248px] portrait:flex-row portrait:px-6 portrait:pt-10 landscape:w-full">
                {menus.map((data, i) => {
                    return (
                        <div
                            className="relative mb-6 h-[60px] w-full cursor-pointer portrait:w-[400px]"
                            key={data.link}
                        >
                            <div className="absolute -ms-4 mb-4 mt-[8px] h-[44px] w-[50%] -skew-x-[22deg] bg-iief-orange drop-shadow portrait:hidden"></div>
                            <div
                                className={
                                    "absolute flex h-[60px] w-full px-6 drop-shadow transition duration-200 ease-in-out hover:scale-105 portrait:px-0 " +
                                    (selected === i
                                        ? "opacity-95"
                                        : "opacity-90")
                                }
                                onClick={() => {
                                    menuSelecting(i, data.link)
                                }}
                            >
                                <div className="flex-none">
                                    <img
                                        alt=""
                                        src={MenuPrefix}
                                        className="h-[60px] w-[30px]"
                                    />
                                </div>
                                <div className="-mr-1 -ms-1 h-[60px] w-[30%] flex-grow overflow-hidden bg-iief-green">
                                    <div className="w-full overflow-hidden py-2 text-white">
                                        <div className="truncate font-semibold">
                                            {data.title}
                                        </div>
                                        <div className="muted truncate text-[11px] font-thin">
                                            {data.description}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-none">
                                    <img
                                        alt=""
                                        src={MenuSubfix}
                                        className="h-[60px] w-[75px]"
                                    />
                                </div>
                            </div>
                            <div className="1s absolute bottom-[-8px] h-1 w-full pe-10 ps-6 portrait:pe-4 portrait:ps-0">
                                <div
                                    className={
                                        "transition-width h-1 bg-iief-green duration-200 ease-in-out " +
                                        (selected === i ? "w-full" : "w-0")
                                    }
                                ></div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default ContainerMenuComponent
