import { useEffect, useState } from "react"
import { BrowserRouter } from "react-router-dom"
import GlobalIdleComponent from "../IdleComponent"
import $ from "jquery"
import LayoutHeaderComponent from "./HeaderComponent"
import LayoutFooterComponent from "./FooterComponent"
import LayoutBackgroundComponent from "./BackgroundComponent"
import LayoutBackdropComponent from "./BackdropComponent"
import LayoutRouterComponent from "./RouterComponent"
import ContainerMenuComponent from "../containers/MenuComponent"
import PatternMenuComponent from "../pattern/MenuComponent"

function LayoutMainComponent() {
    const width = 1366

    const [isFullscreen, setIsFullscreen] = useState(false)

    const toggleFullscreen = () => {
        if (
            !document.fullscreenElement &&
            !document.mozFullScreenElement &&
            !document.webkitFullscreenElement &&
            !document.msFullscreenElement
        ) {
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen()
                setIsFullscreen(true)
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen()
                setIsFullscreen(true)
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen()
                setIsFullscreen(true)
            } else if (document.documentElement.msRequestFullscreen) {
                document.documentElement.msRequestFullscreen()
                setIsFullscreen(true)
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen()
                setIsFullscreen(false)
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen()
                setIsFullscreen(false)
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen()
                setIsFullscreen(false)
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen()
                setIsFullscreen(false)
            } else {
                console.log("nothing")
            }
        }
    }

    useEffect(() => {
        $(window).resize(() => {
            let screenWidth = $(window).width()
            let screenHeight = $(window).height()
            let scale = screenWidth / width
            scale = scale < 1 ? 1 : scale
            $("body").css({
                zoom: scale * 100 + "%",
                opacity: 1,
            })
            $(".h-screen").css({
                height: screenHeight / scale + "px",
            })
        })
        $(window).resize()
    }, [])

    return (
        <div className="relative flex h-screen bg-white" id="parent-content">
            <BrowserRouter>
                <LayoutBackgroundComponent />
                <LayoutBackdropComponent />
                <LayoutHeaderComponent />
                {/* Main */}
                <div className="absolute h-full w-full">
                    {/* Start of content */}
                    <PatternMenuComponent />
                    <ContainerMenuComponent />
                    <div className="no-scrollbar relative mx-2 mt-20 flex pb-14 pt-2 portrait:mt-48 landscape:ms-[30%]">
                        <LayoutRouterComponent className="no-scrollbar" />
                    </div>
                    {/* <PatternContentComponent /> */}
                    {/* End of content */}
                </div>
                <LayoutFooterComponent />
                {/* Fullscreen */}
                {!isFullscreen && (
                    <div
                        className="fixed right-0 top-0 z-50 h-6 w-6 -translate-y-4 translate-x-4 cursor-pointer rounded-bl-lg transition duration-150 ease-in-out hover:translate-x-0 hover:translate-y-0 hover:scale-110 hover:bg-[#087f83] hover:shadow-2xl"
                        onClick={toggleFullscreen}
                    />
                )}
            </BrowserRouter>
            <GlobalIdleComponent />
        </div>
    )
}

export default LayoutMainComponent
