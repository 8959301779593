"use client"
import React, { useState, useEffect } from "react"
import Logo from "../../assets/images/logo.png"
import Lottie from "lottie-react"
import lottieLoading from "../../assets/lotties/loading.json"

const GlobalPreloadComponent = (props) => {
    return (
        <div className="fixed z-50 grid h-full w-full flex-1 place-items-center bg-white">
            <div className="text-center">
                <img className="w-[300px] drop-shadow" src={Logo} />
                <Lottie
                    animationData={lottieLoading}
                    loop={true}
                    className="my-6 h-[100px]"
                />
                <div className="text-gray-400">{props.text}</div>
            </div>
        </div>
    )
}

export default GlobalPreloadComponent
