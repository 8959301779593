import { Component, createRef } from "react"
import Shimmer from "react-shimmer-effect"
import {
    MdOutlineArrowBackIos,
    MdDelete,
    MdEmail,
    MdOutlinePayment,
    MdDiscount,
} from "react-icons/md"
import { RiSecurePaymentLine } from "react-icons/ri"
import swal from "sweetalert"
import CryptoJS from "crypto-js"

import { FiMinus, FiPlus } from "react-icons/fi"
import { IoLogoWhatsapp } from "react-icons/io"
import { BiRename } from "react-icons/bi"
import { FaAddressCard, FaUserCircle, FaMoneyCheckAlt } from "react-icons/fa"
import { IoCartSharp } from "react-icons/io5"

import IndexBloc from "../blocs/index-bloc"
import apiResponse from "../../../services/apiResponse"

class OrderVoucherCheckoutView extends Component {
    indexBloc = new IndexBloc()
    maxOrder = 10
    key = "order.iieflounge.codilabs.id"

    constructor(props) {
        super(props)
        this.state = {
            paymentMethod: [],
            paymentMethodSelected: null,
            paymentMethodError: false,
            paymentMethodLoading: true,
            carts: {
                items: [],
                discount: {
                    discount_uuid: null,
                    discount_code: null,
                    discount_type: null,
                    discount_price: 0,
                    discount_total: 0,
                },
            },
            showError: false,
            emailError: false,
            user: {
                identity: "",
                fullname: "",
                email: "",
                phone: "",
            },

            priceTotalItem: 0,
            priceTotalDiscount: 0,
            priceTotalAdminFee: 0,

            redeemLoading: false,
            redeemExist: true,
            redeemCode: "",

            loadingPayment: false,
            messagePayment: "",
        }
    }

    componentDidMount() {
        this.setState(
            {
                carts: this.props.carts,
            },
            () => {
                this.calculateTotalPrice()
            },
        )

        this.indexBloc.checkDiscountCodeChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        redeemLoading: false,
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        redeemLoading: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.results
                    if (response !== null) {
                        let carts = this.state.carts
                        if (response.product_uuid === null) {
                            carts["discount"] = {
                                discount_uuid: response.uuid,
                                discount_code: response.code,
                                discount_type: parseInt(response.type),
                                discount_price: parseFloat(response.value),
                                discount_total: 0,
                            }
                        } else {
                            carts["items"][response.product_uuid] = {
                                ...carts["items"][response.product_uuid],
                                discount_uuid: response.uuid,
                                discount_code: response.code,
                                discount_type: parseInt(response.type),
                                discount_price: parseFloat(response.value),
                                discount_total: 0,
                            }
                        }
                        this.setState(
                            {
                                carts,
                                redeemCode: "",
                            },
                            () => {
                                this.calculateTotalPrice()
                            },
                        )
                    } else {
                        this.setState({
                            redeemExist: false,
                            redeemCode: "",
                        })
                    }

                    this.setState({
                        redeemLoading: false,
                    })
                    break
                case apiResponse.ERROR:
                    break
                default:
                    break
            }
        })

        this.indexBloc.paymentMethodChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        paymentMethodLoading: true,
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        paymentMethodLoading: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.results
                    let payments = []
                    response.map((payment) => {
                        payments.push({
                            name: payment.name,
                            code: payment.code,
                            logo: payment.logo,
                            min: parseInt(payment.min ?? 0),
                            max: parseInt(payment.max ?? 99999999999),
                            fee: parseInt(payment.fee),
                            active:
                                parseInt(payment.active) === 1 ? true : false,
                        })
                    })
                    console.log(payments)
                    this.setState(
                        {
                            paymentMethod: payments,
                        },
                        () => {
                            this.setState({
                                paymentMethodLoading: false,
                            })
                        },
                    )
                    break
                case apiResponse.ERROR:
                    this.setState({
                        paymentMethodLoading: false,
                    })
                    break
                default:
                    break
            }
        })
        this.indexBloc.fetchPaymentMethod()

        this.indexBloc.orderChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loadingPayment: false,
                        messagePayment: "",
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loadingPayment: true,
                        messagePayment: "",
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.results
                    this.props.payment(response)
                    this.setState({
                        loadingPayment: false,
                        messagePayment: "",
                    })
                    break
                case apiResponse.ERROR:
                    this.setState({
                        loadingPayment: false,
                        messagePayment: result.data,
                    })
                    break
                default:
                    break
            }
        })
    }

    checkDiscountCode = () => {
        let code = this.state.redeemCode
        if (code !== "") {
            let productCode = ""
            Object.keys(this.state.carts.items).forEach((uuid) => {
                productCode += (productCode == "" ? "" : ",") + uuid
            })
            this.setState({
                redeemExist: true,
            })
            this.indexBloc.fetchCheckDiscountCode({
                code,
                uuid: productCode,
            })
        }
    }

    doPayment = () => {
        this.setState({
            showError: true,
        })
        let valid = true
        if (this.state.user.identity === "") {
            valid = false
        }
        if (this.state.user.fullname === "") {
            valid = false
        }
        if (this.state.user.email === "") {
            valid = false
        }
        if (this.state.emailError) {
            valid = false
        }
        if (this.state.user.phone === "") {
            valid = false
        }

        if (valid) {
            swal({
                title: "Are you sure?",
                text:
                    "We'll generate " +
                    this.state.paymentMethodSelected.name +
                    " for payment method.",
                icon: "warning",
                buttons: ["Cancel", "Yes, Sure"],
                dangerMode: true,
            }).then((order) => {
                if (order) {
                    let carts = this.state.carts
                    let items = []
                    Object.keys(carts["items"]).map((uuid) => {
                        items.push(carts["items"][uuid])
                    })
                    let data = JSON.stringify({
                        items: items,
                        discount: this.state.carts["discount"],
                        user: this.state.user,
                        payment: this.state.paymentMethodSelected,
                    })
                    let dataOrder = CryptoJS.AES.encrypt(data, this.key, {
                        format: {
                            stringify: function (cipherParams) {
                                var j = {
                                    ct: cipherParams.ciphertext.toString(
                                        CryptoJS.enc.Base64,
                                    ),
                                }
                                if (cipherParams.iv)
                                    j.iv = cipherParams.iv.toString()
                                if (cipherParams.salt)
                                    j.s = cipherParams.salt.toString()
                                return JSON.stringify(j).replace(/\s/g, "")
                            },
                            parse: function (jsonStr) {
                                var j = JSON.parse(jsonStr)
                                var cipherParams =
                                    CryptoJS.lib.CipherParams.create({
                                        ciphertext: CryptoJS.enc.Base64.parse(
                                            j.ct,
                                        ),
                                    })
                                if (j.iv)
                                    cipherParams.iv = CryptoJS.enc.Hex.parse(
                                        j.iv,
                                    )
                                if (j.s)
                                    cipherParams.salt = CryptoJS.enc.Hex.parse(
                                        j.s,
                                    )
                                return cipherParams
                            },
                        },
                    }).toString()
                    const formDataObject = new FormData()
                    formDataObject.append("order", dataOrder)
                    this.indexBloc.fetchOrder(formDataObject)
                }
            })
        }
    }

    quantityAdd = (uuid) => {
        let carts = this.state.carts
        carts["items"][uuid]["quantity"] =
            carts["items"][uuid]["quantity"] >= this.maxOrder
                ? this.maxOrder
                : carts["items"][uuid]["quantity"] + 1
        this.setState(
            {
                carts,
            },
            () => {
                this.calculateTotalPrice()
            },
        )
    }

    quantityMin = (uuid) => {
        let carts = this.state.carts
        if (carts["items"][uuid]["quantity"] <= 1) {
            swal({
                title: "Are you sure?",
                text: "Cart item with zero quantity will be deleted.",
                icon: "warning",
                buttons: ["Cancel", "Yes, Sure"],
                dangerMode: true,
            }).then((deleted) => {
                if (deleted) {
                    let carts = this.state.carts
                    delete carts["items"][uuid]
                    if (Object.keys(carts["items"]).length === 0) {
                        this.props.choose(carts)
                    } else {
                        this.setState(
                            {
                                carts,
                            },
                            () => {
                                this.calculateTotalPrice()
                            },
                        )
                    }
                }
            })
        }
        carts["items"][uuid]["quantity"] =
            carts["items"][uuid]["quantity"] <= 1
                ? 1
                : carts["items"][uuid]["quantity"] - 1
        this.setState(
            {
                carts,
            },
            () => {
                this.calculateTotalPrice()
            },
        )
    }

    nikHandler = (type, event) => {
        if (type === "keyDown") {
            const allowedKeys = [
                8,
                37,
                39,
                ...Array.from({ length: 10 }, (_, i) => i + 48).concat(
                    ...Array.from({ length: 10 }, (_, i) => i + 96),
                ),
            ]

            if (!allowedKeys.includes(event.keyCode)) {
                event.preventDefault()
            }
        } else if (type === "keyUp") {
            let user = this.state.user
            user.identity = event.target.value
            this.setState({
                user,
            })
        }
    }

    fullnameHandler = (type, event) => {
        if (type === "keyUp") {
            let user = this.state.user
            user.fullname = event.target.value
            this.setState({
                user,
            })
        }
    }

    emailHandler = (type, event) => {
        if (type === "keyUp") {
            let user = this.state.user
            user.email = event.target.value

            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

            this.setState({
                user,
                emailError: !emailRegex.test(user.email),
            })
        }
    }

    waHandler = (type, event) => {
        if (type === "keyDown") {
            const allowedKeys = [
                8,
                37,
                39,
                ...Array.from({ length: 10 }, (_, i) => i + 48).concat(
                    ...Array.from({ length: 10 }, (_, i) => i + 96),
                ),
            ]

            if (!allowedKeys.includes(event.keyCode)) {
                event.preventDefault()
            }
            if (event.target.value === "" && event.keyCode === 48) {
                event.preventDefault()
            }
        } else if (type === "keyUp") {
            let user = this.state.user
            user.phone = event.target.value
            this.setState({
                user,
            })
        }
    }

    calculateTotalPrice = () => {
        let priceTotalItem = 0
        let priceTotalDiscount = 0

        let carts = this.state.carts
        let totalItems = 0
        Object.keys(this.state.carts.items).forEach((uuid) => {
            let item = carts["items"][uuid]
            let totalPrice = item.product_price * item.quantity
            let discountPrice = 0
            if (item.discount_type === 1) {
                discountPrice = item.discount_price * item.quantity
            }
            if (item.discount_type === 2) {
                discountPrice = (totalPrice / 100) * item.discount_price
            }
            carts["items"][uuid] = {
                ...carts["items"][uuid],
                discount_total: discountPrice,
            }
            totalItems += totalPrice - discountPrice

            priceTotalItem += totalPrice
            priceTotalDiscount += discountPrice
        })

        let discountPrice = 0
        let discount = carts["discount"]
        if (discount.discount_type === 1) {
            discountPrice = discount.discount_price
        }
        if (discount.discount_type === 2) {
            discountPrice = (totalItems / 100) * discount.discount_price
        }
        carts["discount"] = {
            ...carts["discount"],
            discount_total: discountPrice,
        }
        priceTotalDiscount += discountPrice

        let paymentMethodSelected = this.state.paymentMethodSelected
        if (paymentMethodSelected !== null) {
            if (
                priceTotalItem - priceTotalDiscount <
                    paymentMethodSelected.min ||
                priceTotalItem - priceTotalDiscount > paymentMethodSelected.max
            ) {
                paymentMethodSelected = null
            }
        }
        this.setState({
            paymentMethodSelected,
            carts,
            priceTotalItem,
            priceTotalDiscount,
            priceTotalAdminFee:
                paymentMethodSelected === null ? 0 : paymentMethodSelected.fee,
        })
    }

    componentWillUnmount() {
        this.indexBloc.checkDiscountCodeChannel.unsubscribe()
        this.indexBloc.paymentMethodChannel.unsubscribe()
        this.indexBloc.orderChannel.unsubscribe()
    }

    render() {
        return (
            <>
                <div className="my-4 mb-8">
                    <div className="border-b-2 pb-2 font-bold text-slate-500">
                        <div className="flex grow">
                            <div className="grow-none pe-2">
                                <IoCartSharp
                                    size={24}
                                    className="text-iief-orange"
                                />
                            </div>{" "}
                            <div className="grow-none text-md">Your Cart</div>
                            <div className="grow ps-2 text-end font-bold text-iief-green">
                                Rp
                                {this.state.priceTotalItem
                                    .toString()
                                    .replace(".", ",")
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                            </div>
                        </div>
                    </div>
                    {Object.values(this.state.carts.items).map((item, key) => {
                        return (
                            <div
                                className="w-full border-b bg-white py-2 ps-8 sm:flex"
                                key={key}
                            >
                                <div className="grow">
                                    <div className="text-sm font-bold text-iief-green">
                                        {item.product_name}
                                    </div>
                                    <div className="text-sm text-gray-600">
                                        {item.category_name}{" "}
                                        <small>{item.category_label}</small>,
                                        @Rp
                                        {item.product_price
                                            .toString()
                                            .replace(".", ",")
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ".",
                                            )
                                            .replace(",00", "")}
                                    </div>
                                </div>
                                <div className="grow-none w-full pt-1 text-end sm:w-32 sm:shrink-0">
                                    <div className="flex w-full justify-end">
                                        {item.quantity === 1 ? (
                                            <div
                                                className="me-1 flex h-8 w-8 cursor-pointer items-center justify-center rounded border-2 border-white bg-red-600 font-bold text-white opacity-80 ring-2 ring-red-600 hover:opacity-100"
                                                onClick={() => {
                                                    if (
                                                        !this.state
                                                            .loadingPayment
                                                    ) {
                                                        this.quantityMin(
                                                            item.product_uuid,
                                                        )
                                                    }
                                                }}
                                            >
                                                <MdDelete size={15} />
                                            </div>
                                        ) : (
                                            <div
                                                className="me-1 flex h-8 w-8 cursor-pointer items-center justify-center rounded border-2 border-white bg-iief-orange px-2 py-2 font-bold text-white opacity-80 ring-2 ring-iief-orange hover:opacity-100"
                                                onClick={() => {
                                                    if (
                                                        !this.state
                                                            .loadingPayment
                                                    ) {
                                                        this.quantityMin(
                                                            item.product_uuid,
                                                        )
                                                    }
                                                }}
                                            >
                                                <FiMinus size={18} />
                                            </div>
                                        )}
                                        <div className="w-[56px] border-2 border-white pt-[1px] text-center text-xl font-bold text-iief-green">
                                            {item.quantity}
                                        </div>
                                        <div
                                            className={
                                                "ms-1 flex h-8 w-8 items-center justify-center rounded " +
                                                (item.quantity >=
                                                    this.maxOrder ||
                                                item.quantity ===
                                                    item.product_stock ||
                                                item.product_multiple === 0
                                                    ? "bg-gray-300 ring-gray-300"
                                                    : "cursor-pointer bg-iief-orange ring-iief-orange hover:opacity-100") +
                                                " border-2 border-white px-2 py-2 font-bold text-white opacity-80 ring-2"
                                            }
                                            onClick={() => {
                                                if (
                                                    !this.state.loadingPayment
                                                ) {
                                                    if (
                                                        item.product_multiple ===
                                                            1 &&
                                                        item.quantity <
                                                            item.product_stock
                                                    ) {
                                                        this.quantityAdd(
                                                            item.product_uuid,
                                                        )
                                                    }
                                                }
                                            }}
                                        >
                                            <FiPlus size={18} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="my-4 mb-8 pt-4">
                    <div className="mb-2 border-b-2 pb-2 font-bold text-slate-500">
                        <div className="flex grow">
                            <div className="grow-none pe-2">
                                <MdDiscount
                                    size={24}
                                    className="text-iief-orange"
                                />
                            </div>{" "}
                            <div className="grow-none text-md">Discount</div>
                            <div className="grow ps-2 text-end font-bold text-iief-green">
                                Rp
                                {this.state.priceTotalDiscount
                                    .toString()
                                    .replace(".", ",")
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                            </div>
                        </div>
                    </div>
                    <div className="flex grow pt-3 text-gray-600">
                        <div className="flex grow justify-end">
                            <div className="h-8 rounded-s bg-iief-orange ps-6 pt-[6px] text-sm text-white">
                                Redeem Code:
                            </div>
                        </div>
                        <div className="grow-none flex h-8 w-48 rounded-e bg-iief-orange py-0 ps-2 text-iief-green">
                            {this.state.redeemLoading ? (
                                <Shimmer>
                                    <div
                                        className=""
                                        style={{
                                            width: "100%",
                                            height: 32,
                                            borderRadius: 0,
                                        }}
                                    ></div>
                                </Shimmer>
                            ) : (
                                <input
                                    className="focus:shadow-outline w-32 w-full border bg-gray-100 px-3 py-1 text-sm text-gray-900 focus:outline-none"
                                    style={{ borderRadius: 0 }}
                                    type="text"
                                    maxLength={64}
                                    placeholder="Code"
                                    onKeyUp={(e) => {
                                        this.setState({
                                            redeemExist: true,
                                            redeemCode: e.target.value,
                                        })
                                    }}
                                />
                            )}
                            <div
                                className={
                                    "flex h-full w-[70px] items-center justify-center rounded-e px-1 " +
                                    (this.state.redeemCode === ""
                                        ? "bg-gray-300"
                                        : this.state.redeemLoading
                                          ? "bg-gray-300"
                                          : "cursor-pointer bg-iief-green") +
                                    " text-sm text-white"
                                }
                                onClick={() => {
                                    if (!this.state.loadingPayment) {
                                        if (!this.state.redeemLoading) {
                                            if (this.state.redeemCode !== "") {
                                                this.checkDiscountCode()
                                            }
                                        }
                                    }
                                }}
                            >
                                Claim
                            </div>
                        </div>
                    </div>
                    {!this.state.redeemExist && (
                        <div className="flex grow">
                            <div className="grow text-end text-xs text-red-500">
                                Rejected! Try another redeem code
                            </div>
                        </div>
                    )}
                    {Object.values(this.state.carts.items).map((item, key) => {
                        return (
                            <>
                                {item.discount_uuid !== null && (
                                    <div className="flex grow pt-3 text-xs text-gray-600">
                                        <div className="grow text-end">
                                            <span className="rounded bg-iief-green px-2 py-[1px] text-xs text-white">
                                                {item.discount_code}
                                            </span>
                                        </div>
                                        <div className="grow-none w-48 ps-2 text-end font-bold text-iief-green">
                                            Rp
                                            {item.discount_total
                                                .toString()
                                                .replace(".", ",")
                                                .toString()
                                                .replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ".",
                                                )}
                                        </div>
                                    </div>
                                )}
                            </>
                        )
                    })}
                    {this.state.carts.discount.discount_uuid !== null && (
                        <div className="flex grow pt-3 text-xs text-gray-600">
                            <div className="grow text-end">
                                <span className="rounded bg-iief-green px-2 py-[1px] text-xs text-white">
                                    {this.state.carts.discount.discount_code}
                                </span>
                            </div>
                            <div className="grow-none w-48 ps-2 text-end font-bold text-iief-green">
                                Rp
                                {this.state.carts.discount.discount_total
                                    .toString()
                                    .replace(".", ",")
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                            </div>
                        </div>
                    )}
                </div>
                <div className="my-4 mb-8 pt-4">
                    <div className="mb-2 border-b-2 pb-2 font-bold text-slate-500">
                        <div className="flex">
                            <div className="pe-2">
                                <FaUserCircle
                                    size={24}
                                    className="text-iief-orange"
                                />
                            </div>{" "}
                            <div className="text-md">Personal Information</div>
                        </div>
                    </div>

                    <div className="ms-8 pb-2 text-sm text-gray-600 sm:flex">
                        <div className="flex w-36 pt-4">
                            <div className="pe-2">
                                <FaAddressCard
                                    size={20}
                                    className="text-iief-green"
                                />
                            </div>{" "}
                            <div className="text-sm">NIK</div>
                        </div>
                        <input
                            //value="321010102008746"
                            disabled={this.state.loadingPayment}
                            className={
                                "focus:shadow-outline mt-2 w-full rounded border text-sm " +
                                (this.state.showError &&
                                this.state.user.identity === ""
                                    ? "border-red-500"
                                    : "") +
                                " bg-gray-100 px-3 py-2 text-gray-900 focus:outline-none sm:max-w-[300px]"
                            }
                            type="text"
                            maxLength={16}
                            placeholder="Identity Number"
                            onKeyDown={(e) => {
                                this.nikHandler("keyDown", e)
                            }}
                            onKeyUp={(e) => {
                                this.nikHandler("keyUp", e)
                            }}
                        />
                    </div>
                    <div className="ms-8 pb-2 text-sm text-gray-600 sm:flex">
                        <div className="flex w-36 pt-4">
                            <div className="pe-2">
                                <BiRename
                                    size={20}
                                    className="text-iief-green"
                                />
                            </div>{" "}
                            <div className="text-sm">Fullname</div>
                        </div>
                        <input
                            //value="Maman Abdurahman"
                            disabled={this.state.loadingPayment}
                            className={
                                "focus:shadow-outline mt-2 w-full rounded border text-sm " +
                                (this.state.showError &&
                                this.state.user.fullname === ""
                                    ? "border-red-500"
                                    : "") +
                                " bg-gray-100 px-3 py-2 text-gray-900 focus:outline-none sm:max-w-[300px]"
                            }
                            type="text"
                            maxLength={64}
                            placeholder="Full Birth Name"
                            onKeyUp={(e) => {
                                this.fullnameHandler("keyUp", e)
                            }}
                        />
                    </div>
                    <div className="ms-8 pb-2 text-sm text-gray-600 sm:flex">
                        <div className="flex w-36 pt-4">
                            <div className="pe-2">
                                <MdEmail size={20} className="text-red-600" />
                            </div>{" "}
                            <div className="text-sm">Email</div>
                        </div>
                        <input
                            //value="anthoplara@gmail.com"
                            disabled={this.state.loadingPayment}
                            className={
                                "focus:shadow-outline mt-2 w-full rounded border text-sm " +
                                (this.state.showError &&
                                (this.state.user.email === "" ||
                                    this.state.emailError)
                                    ? "border-red-500"
                                    : "") +
                                " bg-gray-100 px-3 py-2 text-gray-900 focus:outline-none sm:max-w-[300px]"
                            }
                            type="text"
                            maxLength={96}
                            placeholder="Email Address"
                            onKeyUp={(e) => {
                                this.emailHandler("keyUp", e)
                            }}
                        />
                    </div>
                    <div className="ms-8 pb-2 text-gray-600 sm:flex">
                        <div className="flex w-36 pt-4">
                            <div className="pe-2">
                                <IoLogoWhatsapp
                                    size={20}
                                    className="text-green-600"
                                />
                            </div>{" "}
                            <div className="text-sm">WhatsApp</div>
                        </div>
                        <div className="flex w-full sm:max-w-[300px]">
                            <span
                                className={
                                    "mt-2 rounded-l border " +
                                    (this.state.showError &&
                                    this.state.user.phone === ""
                                        ? "border-red-500"
                                        : "") +
                                    " border-r-0 bg-gray-100 py-2 ps-3 text-gray-900 focus:outline-none"
                                }
                            >
                                +62
                            </span>
                            <input
                                //value="87665476876"
                                disabled={this.state.loadingPayment}
                                className={
                                    "focus:shadow-outline mt-2 w-full rounded-r border text-sm " +
                                    (this.state.showError &&
                                    this.state.user.phone === ""
                                        ? "border-red-500"
                                        : "") +
                                    " border-l-0 bg-gray-100 px-3 py-2 text-gray-900 focus:outline-none"
                                }
                                type="text"
                                maxLength={12}
                                placeholder="WhatsApp Number"
                                onKeyDown={(e) => {
                                    this.waHandler("keyDown", e)
                                }}
                                onKeyUp={(e) => {
                                    this.waHandler("keyUp", e)
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="my-4 mb-8 pt-4">
                    <div className="mb-2 border-b-2 pb-2 font-bold text-slate-500">
                        <div className="flex grow">
                            <div className="grow-none pe-2">
                                <MdOutlinePayment
                                    size={24}
                                    className="text-iief-orange"
                                />
                            </div>{" "}
                            <div className="grow-none text-md">
                                Payment Method
                            </div>
                            {!this.state.paymentMethodLoading &&
                            this.state.paymentMethodSelected !== null ? (
                                <div className="grow ps-2 text-end font-bold text-iief-green">
                                    Rp
                                    {this.state.paymentMethodSelected.fee
                                        .toString()
                                        .replace(".", ",")
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                                </div>
                            ) : (
                                <div className="grow ps-2 text-end font-bold text-iief-green">
                                    Rp0
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="ms-8 pb-2 text-gray-600">
                        <div className="bg-stripes-fuchsia grid gap-4 rounded-lg pt-2 text-center leading-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
                            {this.state.paymentMethodLoading ? (
                                <></>
                            ) : (
                                <>
                                    {this.state.paymentMethod.map(
                                        (payment, key) => {
                                            let total =
                                                this.state.priceTotalItem -
                                                this.state.priceTotalDiscount
                                            let active =
                                                payment.active &&
                                                total >= payment.min &&
                                                total <= payment.max
                                            return (
                                                <div
                                                    key={key}
                                                    className={
                                                        (this.state
                                                            .paymentMethodError &&
                                                        active
                                                            ? "ring-red-500"
                                                            : "") +
                                                        " rounded-lg border-2 border-white bg-gray-100 p-2 pb-0 " +
                                                        (active
                                                            ? "cursor-pointer opacity-70 hover:opacity-100"
                                                            : "opacity-20") +
                                                        " shadow ring-2 " +
                                                        (this.state
                                                            .paymentMethodSelected !==
                                                            null &&
                                                        this.state
                                                            .paymentMethodSelected
                                                            .code ===
                                                            payment.code
                                                            ? "ring-iief-green"
                                                            : "ring-gray-300")
                                                    }
                                                    onClick={() => {
                                                        if (
                                                            !this.state
                                                                .loadingPayment
                                                        ) {
                                                            if (active) {
                                                                this.setState({
                                                                    priceTotalAdminFee:
                                                                        payment.fee,
                                                                    paymentMethodSelected:
                                                                        payment,
                                                                })
                                                            }
                                                        }
                                                    }}
                                                >
                                                    <img
                                                        src={payment.logo}
                                                        style={{ height: 22 }}
                                                        className={
                                                            "mx-auto " +
                                                            (active
                                                                ? ""
                                                                : "opacity-50 blur-xs")
                                                        }
                                                    />
                                                    <span className="text-[9px]">
                                                        Admin Fee:{" "}
                                                        {payment.fee === 0 ? (
                                                            "Free"
                                                        ) : (
                                                            <>
                                                                Rp
                                                                {payment.fee
                                                                    .toString()
                                                                    .replace(
                                                                        ".",
                                                                        ",",
                                                                    )
                                                                    .toString()
                                                                    .replace(
                                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                                        ".",
                                                                    )}
                                                            </>
                                                        )}
                                                    </span>
                                                </div>
                                            )
                                        },
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>

                <div className="my-4 mb-8 pt-4">
                    <div className="mb-2 border-b-2 pb-2 font-bold text-slate-500">
                        <div className="flex grow">
                            <div className="grow-none pe-2">
                                <FaMoneyCheckAlt
                                    size={24}
                                    className="text-iief-orange"
                                />
                            </div>{" "}
                            <div className="grow-none text-md">
                                Total Payment
                            </div>
                            <div className="grow ps-2 text-end font-bold text-iief-green">
                                Rp
                                {(
                                    this.state.priceTotalItem -
                                    this.state.priceTotalDiscount +
                                    this.state.priceTotalAdminFee
                                )
                                    .toString()
                                    .replace(".", ",")
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-between">
                        <div
                            className="my-[10px] me-4 flex h-[34px] scale-[90%] cursor-pointer overflow-hidden rounded-full border-2 border-white bg-iief-orange pe-6 ps-4 pt-[2.2px] shadow-md ring-2 ring-iief-orange transition duration-200 ease-in-out hover:scale-100"
                            onClick={() => {
                                if (!this.state.loadingPayment) {
                                    this.props.choose(this.state.carts)
                                }
                            }}
                        >
                            <MdOutlineArrowBackIos className="me-2 mt-1 text-white" />
                            <p className="text-md flex truncate text-ellipsis text-white">
                                Back
                            </p>
                        </div>
                        {this.state.loadingPayment ? (
                            <Shimmer>
                                <div
                                    className=""
                                    style={{
                                        marginTop: 11,
                                        width: 115,
                                        height: 32,
                                        borderRadius: 16,
                                    }}
                                ></div>
                            </Shimmer>
                        ) : (
                            <div
                                className="my-[10px] flex h-[34px] scale-[90%] cursor-pointer overflow-hidden rounded-full border-2 border-white bg-iief-green pe-6 ps-4 pt-[2.2px] shadow-md ring-2 ring-iief-green transition duration-200 ease-in-out hover:scale-100"
                                onClick={() => {
                                    if (!this.state.loadingPayment) {
                                        if (
                                            this.state.paymentMethodSelected !==
                                            null
                                        ) {
                                            this.doPayment()
                                        } else {
                                            this.setState({
                                                paymentMethodError: true,
                                            })
                                            setTimeout(() => {
                                                this.setState({
                                                    paymentMethodError: false,
                                                })
                                            }, 1000)
                                        }
                                    }
                                }}
                            >
                                <RiSecurePaymentLine className="me-2 mt-1 text-white" />
                                <p className="text-md flex truncate text-ellipsis text-white">
                                    Proceed
                                </p>
                            </div>
                        )}
                    </div>
                    <div
                        style={{
                            textAlign: "right",
                            fontSize: 11,
                            color: "#ff0000",
                        }}
                    >
                        {this.state.messagePayment}
                    </div>
                </div>
            </>
        )
    }
}

export default OrderVoucherCheckoutView
