import { Component } from "react"
import Shimmer from "react-shimmer-effect"
import IndexBloc from "../blocs/index-bloc"
import apiResponse from "../../../services/apiResponse"

import Tlogo from "../../../assets/images/t.png"

import { IoCartOutline, IoCartSharp } from "react-icons/io5"
import { MdOutlineShoppingCartCheckout } from "react-icons/md"

class OrderVoucherChooseView extends Component {
    indexBloc = new IndexBloc()

    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            showMore: [],
            products: [],
            carts: {
                items: [],
                discount: {
                    discount_uuid: null,
                    discount_code: null,
                    discount_type: null,
                    discount_price: 0,
                    total_price: 0,
                },
            },
        }
    }

    setShowMore = (uuid) => {
        let showMore = this.state.showMore
        showMore[uuid] = !showMore[uuid]
        this.setState({
            showMore,
        })
    }

    setCart = (uuid, category, product) => {
        let carts = this.state.carts
        if (carts["items"].hasOwnProperty(uuid)) {
            delete carts["items"][uuid]
        } else {
            carts["items"][uuid] = {
                category_uuid: category.category_uuid,
                category_name: category.category_name,
                category_label: category.category_label,
                product_uuid: product.product_uuid,
                product_name: product.product_name,
                product_price: parseFloat(product.product_price_value),
                product_multiple: parseFloat(product.product_multiple),
                product_stock: parseInt(product.product_stock),
                quantity: 1,
                discount_uuid: null,
                discount_code: null,
                discount_type: null,
                discount_price: 0,
                discount_total: 0,
            }
        }
        this.setState({
            carts,
        })
    }

    componentDidMount() {
        this.setState({
            carts: this.props.carts,
        })
        this.indexBloc.productDataChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loading: false,
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loading: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.results
                    let showMore = []
                    response.map((data, _) => {
                        data.products.map((item, _) => {
                            showMore[item.product_uuid] = false
                        })
                    })
                    this.setState({
                        showMore,
                        //carts: this.defaultCart,
                        products: response,
                        loading: false,
                    })
                    break
                case apiResponse.ERROR:
                    break
                default:
                    break
            }
        })
        setTimeout(() => {
            this.indexBloc.fetchProductData()
        }, 500)
    }

    componentWillUnmount() {
        this.indexBloc.productDataChannel.unsubscribe()
    }

    render() {
        return (
            <>
                {this.state.loading ? (
                    <div className="my-4 mb-8">
                        <div className="text-md flex border-b-2 pb-2 font-bold text-iief-green">
                            <Shimmer>
                                <div
                                    className="mt-2 rounded"
                                    style={{
                                        width: 100,
                                        height: 16,
                                        paddingBottom: 0,
                                        marginBottom: 0,
                                    }}
                                ></div>
                            </Shimmer>
                            <Shimmer>
                                <div
                                    className="ms-2 rounded-full"
                                    style={{
                                        width: 80,
                                        height: 16,
                                        paddingBottom: 0,
                                        marginBottom: 0,
                                    }}
                                ></div>
                            </Shimmer>
                        </div>
                        {Array.from({ length: 3 }, (_, index) => {
                            return (
                                <div
                                    className="w-full border-b bg-white py-2 ps-6"
                                    key={index}
                                >
                                    <div>
                                        <div className="flex">
                                            <div className="h-[52px] w-[44px] py-2 pe-2">
                                                <div className="h-[36px] rounded border-2 border-gray-300 p-1 pt-[5px]">
                                                    <img
                                                        className="opacity-40 grayscale"
                                                        alt="T Logo"
                                                        src={Tlogo}
                                                    />
                                                </div>
                                            </div>
                                            <div className="ms-2 flex-col">
                                                <div>
                                                    <Shimmer>
                                                        <div
                                                            className="rounded"
                                                            style={{
                                                                width: 150,
                                                                height: 16,
                                                                paddingBottom: 0,
                                                                marginBottom: 0,
                                                            }}
                                                        ></div>
                                                    </Shimmer>
                                                </div>
                                                <Shimmer>
                                                    <div
                                                        className="rounded"
                                                        style={{
                                                            width: 80,
                                                            height: 18,
                                                            paddingBottom: 0,
                                                            marginBottom: 0,
                                                        }}
                                                    ></div>
                                                </Shimmer>
                                            </div>
                                        </div>
                                        <div className="mb-1 mt-1 grow text-xs text-slate-400">
                                            <Shimmer>
                                                <div
                                                    className="rounded"
                                                    style={{
                                                        width: "100%",
                                                        height: 12,
                                                        paddingBottom: 0,
                                                        marginBottom: 0,
                                                    }}
                                                ></div>
                                                <div
                                                    className="rounded"
                                                    style={{
                                                        width: "50%",
                                                        height: 12,
                                                        paddingBottom: 0,
                                                        marginBottom: 0,
                                                    }}
                                                ></div>
                                            </Shimmer>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                ) : (
                    <>
                        {this.state.products.map((category, i) => {
                            return (
                                <div
                                    className="my-4 mb-8"
                                    key={category.category_uuid}
                                >
                                    <div className="text-md border-b-2 pb-2 font-bold text-iief-green">
                                        <span className="me-2 pt-2">
                                            {category.category_name}
                                        </span>
                                        <sup>
                                            <span className="rounded-full bg-iief-green px-[6px] py-[2px] text-[10px] font-normal text-white">
                                                {category.category_label}
                                            </span>
                                        </sup>
                                    </div>
                                    {category.products.map((product, i) => {
                                        return (
                                            <div
                                                className={
                                                    "w-full border-b bg-white py-2 ps-6 " +
                                                    (parseInt(
                                                        product.product_stock,
                                                    ) === 0 ||
                                                    parseInt(
                                                        product.product_for_sale,
                                                    ) === 0
                                                        ? "opacity-50"
                                                        : "")
                                                }
                                                key={product.product_uuid}
                                            >
                                                <div>
                                                    <div
                                                        className="flex"
                                                        onClick={() => {
                                                            if (
                                                                parseInt(
                                                                    product.product_stock,
                                                                ) > 0 &&
                                                                parseInt(
                                                                    product.product_for_sale,
                                                                ) === 1
                                                            ) {
                                                                this.setCart(
                                                                    product.product_uuid,
                                                                    category,
                                                                    product,
                                                                )
                                                            }
                                                        }}
                                                    >
                                                        <div className="h-[52px] w-[44px] py-2 pe-2">
                                                            <div
                                                                className={
                                                                    "h-[36px] rounded border-2 " +
                                                                    (this.state.carts.items.hasOwnProperty(
                                                                        product.product_uuid,
                                                                    )
                                                                        ? "border-iief-orange"
                                                                        : "border-gray-300") +
                                                                    " p-1 pt-[5px]"
                                                                }
                                                            >
                                                                {this.state.carts.items.hasOwnProperty(
                                                                    product.product_uuid,
                                                                ) ? (
                                                                    <img
                                                                        alt="T Logo"
                                                                        src={
                                                                            Tlogo
                                                                        }
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        className="opacity-40 grayscale"
                                                                        alt="T Logo"
                                                                        src={
                                                                            Tlogo
                                                                        }
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="ms-2 pb-1">
                                                            <div
                                                                className={
                                                                    "text-sm " +
                                                                    (this.state.carts.items.hasOwnProperty(
                                                                        product.product_uuid,
                                                                    )
                                                                        ? "font-bold"
                                                                        : "font-normal") +
                                                                    " text-iief-green"
                                                                }
                                                            >
                                                                {
                                                                    product.product_name
                                                                }
                                                            </div>
                                                            <span
                                                                className={
                                                                    "rounded " +
                                                                    (this.state.carts.items.hasOwnProperty(
                                                                        product.product_uuid,
                                                                    )
                                                                        ? "bg-iief-orange"
                                                                        : "bg-gray-400") +
                                                                    " px-2 py-[2px] text-xs text-white"
                                                                }
                                                            >
                                                                Rp
                                                                {product.product_price_value
                                                                    .toString()
                                                                    .replace(
                                                                        ".",
                                                                        ",",
                                                                    )
                                                                    .toString()
                                                                    .replace(
                                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                                        ".",
                                                                    )
                                                                    .replace(
                                                                        ",00",
                                                                        "",
                                                                    )}
                                                            </span>
                                                            {parseInt(
                                                                product.product_for_sale,
                                                            ) === 1 ? (
                                                                <>
                                                                    {parseInt(
                                                                        product.product_stock,
                                                                    ) === 0 && (
                                                                        <span className="ms-1 text-xs text-red-500">
                                                                            Out
                                                                            of
                                                                            Stock
                                                                        </span>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <span className="ms-1 text-xs text-red-500">
                                                                    Not
                                                                    availabled
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={
                                                            "mb-1 mt-1 grow text-xs " +
                                                            (this.state
                                                                .showMore[
                                                                product
                                                                    .product_uuid
                                                            ]
                                                                ? "line-clamp-none"
                                                                : "line-clamp-2") +
                                                            " text-slate-400"
                                                        }
                                                        onClick={() => {
                                                            this.setShowMore(
                                                                product.product_uuid,
                                                            )
                                                        }}
                                                    >
                                                        {
                                                            product.product_description
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </>
                )}
                <div className="flex justify-end">
                    {Object.keys(this.state.carts.items).length === 0 ? (
                        <div className="my-[10px] flex h-[34px] scale-[90%] cursor-pointer overflow-hidden rounded-full border-2 border-white bg-gray-400 pe-6 ps-4 pt-[2.2px] shadow-md ring-2 ring-gray-400 transition duration-200 ease-in-out">
                            <MdOutlineShoppingCartCheckout className="me-2 mt-1 text-white" />
                            <p className="text-md truncate text-ellipsis text-white">
                                Checkout
                            </p>
                        </div>
                    ) : (
                        <div
                            className="my-[10px] flex h-[34px] scale-[90%] cursor-pointer overflow-hidden rounded-full border-2 border-white bg-iief-green pe-6 ps-4 pt-[2.2px] shadow-md ring-2 ring-iief-green transition duration-200 ease-in-out hover:scale-100"
                            onClick={() => {
                                this.props.checkout(this.state.carts)
                            }}
                        >
                            <MdOutlineShoppingCartCheckout className="me-2 mt-1 text-white" />
                            <p className="text-md flex truncate text-ellipsis text-white">
                                Checkout{" "}
                                <span className="ms-2 pt-1 text-xs">
                                    {"("}
                                    {
                                        Object.keys(this.state.carts.items)
                                            .length
                                    }{" "}
                                    item
                                    {Object.keys(this.state.carts.items)
                                        .length > 1
                                        ? "s"
                                        : ""}
                                    {")"}
                                </span>
                            </p>
                        </div>
                    )}
                </div>
            </>
        )
    }
}

export default OrderVoucherChooseView
