import { Component } from "react"
import Shimmer from "react-shimmer-effect"

import { MdLocalPhone, MdEmail } from "react-icons/md"
import { FaFax } from "react-icons/fa"
import { FaGlobe } from "react-icons/fa6"

import IndexBloc from "../blocs/index-bloc"
import apiResponse from "../../../services/apiResponse"

class ContactUsView extends Component {
    indexBloc = new IndexBloc()

    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            contactUsData: null,
        }
    }

    componentDidMount() {
        this.indexBloc.listChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loading: false,
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        loading: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.results
                    this.setState({
                        contactUsData: response,
                        loading: false,
                    })
                    break
                case apiResponse.ERROR:
                    break
                default:
                    break
            }
        })
        setTimeout(() => {
            this.indexBloc.fetchList()
        }, 500)
    }

    componentWillUnmount() {
        this.indexBloc.listChannel.unsubscribe()
    }

    render() {
        return (
            <div className="relative mx-auto w-full rounded-2xl bg-white shadow-2xl">
                <div className="flex px-6 py-5">
                    <div className="relative bottom-0 flex h-12 w-full">
                        <div className="absolute ms-3 h-10 w-[210px] flex-1 skew-x-[-22deg] transform border-2 border-iief-orange"></div>
                        <div className="absolute mt-2 h-10 w-[210px] flex-1 skew-x-[-22deg] transform bg-iief-green text-xs drop-shadow">
                            <div className="text-md flex skew-x-[22deg] transform items-center justify-center gap-2 pe-12">
                                <div className="mb-6 px-6 pt-[7px] text-xl font-bold text-white">
                                    Contact Us
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-2">
                    {/* :MAP CONTAINER */}
                    <div className="order-1 col-span-full">
                        {this.state.loading ? (
                            <div className="h-[400px] w-full bg-gray-200" />
                        ) : (
                            <iframe
                                src={this.state.contactUsData.map}
                                title="map"
                                width="100%"
                                height="400px"
                                loading="lazy"
                            />
                        )}
                    </div>

                    <div className="order-3 col-span-full px-6 py-5 md:order-2 md:col-span-1">
                        <div className="mx-auto flex max-w-xl flex-col">
                            <div className="mb-2 text-sm font-bold text-iief-green">
                                {this.state.loading ? (
                                    <Shimmer>
                                        <div
                                            className="rounded"
                                            style={{
                                                width: 90,
                                                height: 14,
                                                paddingBottom: 0,
                                                marginBottom: 0,
                                            }}
                                        ></div>
                                    </Shimmer>
                                ) : (
                                    <>{this.state.contactUsData.name}</>
                                )}
                            </div>
                            {this.state.loading ? (
                                <>
                                    <div className="text-xs text-gray-500">
                                        <Shimmer>
                                            <div
                                                className="rounded"
                                                style={{
                                                    width: "100%",
                                                    height: 12,
                                                    paddingBottom: 0,
                                                    marginBottom: 0,
                                                }}
                                            ></div>
                                        </Shimmer>
                                    </div>
                                    <div className="text-xs text-gray-500">
                                        <Shimmer>
                                            <div
                                                className="rounded"
                                                style={{
                                                    width: "100%",
                                                    height: 12,
                                                    paddingBottom: 0,
                                                    marginBottom: 0,
                                                }}
                                            ></div>
                                        </Shimmer>
                                    </div>
                                    <div className="text-xs text-gray-500">
                                        <Shimmer>
                                            <div
                                                className="rounded"
                                                style={{
                                                    width: "80%",
                                                    height: 12,
                                                    paddingBottom: 0,
                                                    marginBottom: 0,
                                                }}
                                            ></div>
                                        </Shimmer>
                                    </div>
                                </>
                            ) : (
                                <div className="text-xs text-gray-500">
                                    {this.state.contactUsData.address}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="order-2 col-span-full px-6 py-5 md:order-3 md:col-span-1">
                        <div class="grid grid-cols-2 gap-4 pt-2 text-gray-500">
                            <div class="flex items-center">
                                <MdLocalPhone className="text-iief-green" />
                                {this.state.loading ? (
                                    <Shimmer>
                                        <div
                                            className="ms-2 rounded"
                                            style={{
                                                width: "60%",
                                                height: 14,
                                                paddingBottom: 0,
                                                marginTop: 5,
                                                marginBottom: 5,
                                            }}
                                        ></div>
                                    </Shimmer>
                                ) : (
                                    <span className="ps-2">
                                        {this.state.contactUsData.phone}
                                    </span>
                                )}
                            </div>
                            <div class="flex items-center">
                                <FaFax className="text-iief-green" />
                                {this.state.loading ? (
                                    <Shimmer>
                                        <div
                                            className="ms-2 rounded"
                                            style={{
                                                width: "60%",
                                                height: 14,
                                                paddingBottom: 0,
                                                marginTop: 5,
                                                marginBottom: 5,
                                            }}
                                        ></div>
                                    </Shimmer>
                                ) : (
                                    <span className="ps-2">
                                        {this.state.contactUsData.fax}
                                    </span>
                                )}
                            </div>
                            <div class="flex items-center">
                                <MdEmail className="text-iief-green" />
                                {this.state.loading ? (
                                    <Shimmer>
                                        <div
                                            className="ms-2 rounded"
                                            style={{
                                                width: "60%",
                                                height: 14,
                                                paddingBottom: 0,
                                                marginTop: 5,
                                                marginBottom: 5,
                                            }}
                                        ></div>
                                    </Shimmer>
                                ) : (
                                    <span className="ps-2">
                                        {this.state.contactUsData.email}
                                    </span>
                                )}
                            </div>
                            <div class="flex items-center">
                                <FaGlobe className="text-iief-green" />
                                {this.state.loading ? (
                                    <Shimmer>
                                        <div
                                            className="ms-2 rounded"
                                            style={{
                                                width: "60%",
                                                height: 14,
                                                paddingBottom: 0,
                                                marginTop: 5,
                                                marginBottom: 5,
                                            }}
                                        ></div>
                                    </Shimmer>
                                ) : (
                                    <span className="ps-2">
                                        {this.state.contactUsData.website}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ContactUsView
